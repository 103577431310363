class AjaxComponent {
  constructor() {
    this.baseURL = 'https://api.fair-model.top/';
  }

  async request(endpoint, method = 'GET', data = null) {
    const url = `${this.baseURL}${endpoint}`;
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }

  async get(endpoint) {
    return await this.request(endpoint, 'GET');
  }

  async post(endpoint, data) {
    return await this.request(endpoint, 'POST', data);
  }

  async put(endpoint, data) {
    return await this.request(endpoint, 'PUT', data);
  }

  async delete(endpoint) {
    return await this.request(endpoint, 'DELETE');
  }
}
